import React from "react";
import "../css/Slider.css";
import img_1 from "../img/slider3.jpg"

function Slider_3(){
    return(
        <div className="slider">
            <img className="image_container" src={img_1}/>
        </div>
    )
}

export default Slider_3;