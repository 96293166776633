import React from "react";
import img_1 from "../img/slider1.jpg";
import "../css/Slider.css";

function Slider_1(){
    return(
        <div className="slider">
            <img className="image_container" src={img_1}/>
        </div>
    )
}

export default Slider_1;