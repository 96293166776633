import React, { useState } from "react";
import ic_hamburger_menu from "../img/ic_hamburger_menu.png";
import ic_cubix_logo from "../img/logo_cubix.png";
import "../css/Header.css";
import { useNavigate } from "react-router-dom";
import SideDrawer from "./SideDrawer";
import ic_search from "../img/ic_search_customer.png";
import axios from "axios";
import { useEffect } from "react";

function HeaderTvMonitor(props) {

    const [countOfTicketsClosedToday, setCountOfTicketsClosedToday] = useState(0)
    const [openedTicketsCountState, setOpenedTicketsCountState] = useState(0);


    const getCountOfTicketCategory = (totalArrayTicket) => {

        if (totalArrayTicket != null) {
            let openedTicketsCount = totalArrayTicket.reduce((count, item) => item.ticket_current_status == 1 ? count = count + 1 : count, 0);

            setOpenedTicketsCountState(openedTicketsCount);
        }

    }

    const getAllOpenedTicketsFromCustomer = () => {


        axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-opened-ticket-from-customer.php`).
            then((res) => {

                // setShowLoadingUiState(false);
                console.log(" all opened tickets from customer  res is >> ", res);

                if (res.data.status == "get_opened_tickets_success") {

                    getCountOfTicketCategory(res.data.opened_tickets_array);

                } else if (res.data.status == "get_opened_tickets_failure") {

                    console.log("err is Databse error ");
                }
            }).
            catch((err) => {

                console.log("err is ", err);
            })
    }

    const geCountOfClosedTicketsToday = () => {


        axios.get(`${process.env.REACT_APP_BASE_URL}/get-count-of-ticket-closed-today.php`).
            then((res) => {

                // setShowLoadingUiState(false);
                console.log("count of closed tickets ---+++ ", res);

                if (res.data.status == "success") {


                    setCountOfTicketsClosedToday(res.data.data[0].count_of_tickets_closed_today);

                } else if (res.data.status == "failure") {

                    console.log("err is Databse error ");
                }
            }).
            catch((err) => {

                console.log("err is ", err);
            })
    }


    useEffect(() => {

        getAllOpenedTicketsFromCustomer();
        geCountOfClosedTicketsToday();


        setInterval(() => {
            getAllOpenedTicketsFromCustomer();
            geCountOfClosedTicketsToday();
        }, 50000); // refresh after 5 minutes



    }, [])

    return (
        <div className="Header-root-container">
            <div className="Header-panel">

                <div>
                    <a href="/admindashboard" className="ms-4 Header-panel-company-logo">
                        <img src={ic_cubix_logo} />
                    </a>
                    <label>Support Center</label>
                </div>

                <div className="d-flex">
                    <span className="TicketsInHomeComponentTvMonitor-statistics-container-img-bg">
                        <label>OPENED</label>
                        <label className="ms-2">{openedTicketsCountState}</label>
                    </span>


                    <span className="TicketsInHomeComponentTvMonitor-statistics-container-img-bg-second_card ms-0">
                        <label>Total Tickets Closed Today</label>
                        <label className="ms-2">{countOfTicketsClosedToday}</label>
                    </span>
                </div>

            </div>


        </div>
    )

}

export default HeaderTvMonitor;