import React, { useState, useRef } from "react";
import ic_check_mark from "../img/ic_checkmark_complete.png";
import "../css/TicketSingleCard.css";
import CompletionPopup from "./CompletionPopup";
import { Link } from "react-router-dom";
import ic_view_details from "../img/ic_view_details.png";
import ic_change_status from "../img/ic_edit_status.png";

function TicketSingleCardSupportTvMonitor(props) {

    const { openedTicketFromCustomerArrayState } = props;


    return (

        <div className="TicketSingleCard-root-container ms-3 me-3">

            {
                <table className="TicketSingleCard-table">
                    <thead>
                        <tr>
                            <th><div>Company Name</div></th>
                            <th><div>Created On</div></th>
                            <th><div>Description</div></th>
                            <th><div>Mobile Number</div></th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            openedTicketFromCustomerArrayState != null ?
                                openedTicketFromCustomerArrayState.map((item, index) => {
                                    return (

                                        <tr key={item.id} className={item.ticket_remarks == "ticket from customer" ? "TicketSingleCard-list-container-single-item-direct-from-customer" : "TicketSingleCard-list-container-single-item"} >


                                            <td>
                                                <div className="TicketSingleCard-table-company-name-column">
                                                    <p title={item.company_name} className="from-customer-value-text mb-0">

                                                        {item.company_name}

                                                    </p>
                                                </div>
                                            </td>

                                            <td>
                                                <div>

                                                    <p className="from-customer-value-text mb-0">
                                                        {new Date(item.ticket_created_on).getDate() +
                                                            " / " + (new Date(item.ticket_created_on).getMonth() + 1) +
                                                            " / " + (new Date(item.ticket_created_on).getFullYear())
                                                        }</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="TicketSingleCard-table-description-column">
                                                    <p
                                                        onClick={() => window.open(`/admindashboard/ticket-details/${item.id}`)}
                                                        title={item.ticket_description} className="from-customer-value-text mb-0">
                                                        <span className="TicketSingleCard_support_ticket_id_badge me-2">{item.id}</span>
                                                        {item.ticket_description}
                                                    </p>
                                                    {item.remarks ? <p className="TicketSingleCard_support_person_name_paragraph">{item.remarks}</p> : null}
                                                </div>
                                            </td>

                                            {/* actually we get company id and save it to company name , may be we can call intermediate API by abhilash sir
                                            to get original id and name of company then return it to front end ie to here,*/}


                                            <td>
                                                {
                                                    item.ticket_remarks == "ticket from cubix staff" ?
                                                        <div >
                                                            <p className="from-customer-value-text ">

                                                                {item.mobile_number} <br />
                                                                by cubix staff
                                                            </p>
                                                        </div>
                                                        :
                                                        <div >
                                                            <p className="from-customer-value-text  mb-0">

                                                                {item.mobile_number}
                                                                <span className="ms-3">
                                                                    {item.ticket_assigned_by}
                                                                </span>
                                                            </p>
                                                        </div>

                                                }

                                            </td>

                                           




                                        </tr>
                                    )
                                })
                                :
                                null
                        }
                    </tbody>
                </table>
            }


        </div>
    )
}

export default TicketSingleCardSupportTvMonitor;