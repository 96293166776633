import React, { useEffect, useState, useRef } from "react";
import "../css/AdminDashboard.css";
import DailyTasksInHomeComponent from "./DailyTasksInHomeComponent";
import axios from "axios";
import LoadingLayout from "./LoadingLayout";
import TicketsInHomeComponentSupport from "./TicketsInHomeComponentSupport";
import SideSmallDrawer from "./SideSmallDrawer";
import TicketsInHomeComponentSupportTvMonitor from "./TicketsInHomeComponentSupportTvMonitor";
import HeaderTvMonitor from "./HeaderTvMonitor";

function AdminDashboardTvMonitor() {

    const [stateForChild, setStateForChild] = useState(null);
    const [allAssetsStoredState, setAllAssetsStoredState] = useState(false);
    const [showLoadingLayout, setShowLoadingLayout] = useState(false);
    const [isStaffNameStoredState, setIsStaffNameStoredState] = useState(false);

    const getAllStaffName = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-staff-name.php`).
            then((res) => {
                console.log(" all staff names res is ", res);
                if (res.data.status == "get_staff_name_success") {

                    let stringArray = JSON.stringify(res.data.staff_name_array);
                    sessionStorage.setItem("cubix_task_ticket_staff_name_array", stringArray);

                    setIsStaffNameStoredState(true);

                }
            }).
            catch((err) => {
                console.log("err is ", err);
            })
    }
    

    useEffect(() => {

        if (isStaffNameStoredState ) {
            setShowLoadingLayout(false);
            setAllAssetsStoredState(true)
        }

    }, [isStaffNameStoredState])


    useEffect(() => {
        setShowLoadingLayout(true);
        getAllStaffName();
    }, [])

    const funcFromParent = (valueFromHeader)=>{
        console.log("funcFromParent value ");
        setStateForChild(valueFromHeader);
    }


    return (
        <div className="AdminDashboard-root-container">

            <HeaderTvMonitor funcFromParent={funcFromParent}/>


            {/* Desktop */}
            {allAssetsStoredState &&
                <div className="show-only-in-desktop Ticket-SideSmallDrawer-container">

                    
                    <TicketsInHomeComponentSupportTvMonitor stateForChild={stateForChild}/>
                </div>
            }

            {/* Mobile */}
            {allAssetsStoredState &&
                <div className="show-only-in-mobile">
                  
                    <TicketsInHomeComponentSupportTvMonitor />
                </div>
            }

            {
                showLoadingLayout && <LoadingLayout />
            }

            

        </div>
    )

}

export default AdminDashboardTvMonitor;