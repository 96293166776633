import React, { useState, useEffect, useRef } from "react";
import ic_ticket from "../img/ic_ticket.png";
import "../css/TicketsInHomeComponentTvMonitor.css";
import CreateTicketPopupContainer from "./CreateTicketPopupContainer";
import axios from "axios";
import LoadingUI from "./LoadingUI";
import FilterPopupContainer from "./FilterPopupContainer";
import ic_closed_tickets from "../img/ic_completed_tickets.png";
import ic_filter_tickets from "../img/ic_filter_tickets.png";
import ic_create_ticket from "../img/ic_create_ticket_plus.png";
import ic_search from "../img/ic_search_customer.png";
import TicketSingleCardSupportTvMonitor from "./TicketSingleCardSupportTvMonitor";
import { isEqual } from "lodash";

import Marquee from "react-fast-marquee";

import roll1 from "../img/roll1.png";
import roll2 from "../img/roll2.png";
import roll3 from "../img/roll3.png";
import roll4 from "../img/roll4.png";
import roll5 from "../img/roll5.png";
import roll6 from "../img/roll6.png";
import roll7 from "../img/roll7.png";

import Slider_1 from "../components/Slider_1";
import Slider_2 from "../components/Slider_2";
import Slider_3 from "../components/Slider_3";
import Slider_4 from "../components/Slider_4";
import Slider_5 from "../components/Slider_5";

function TicketsInHomeComponentSupportTvMonitor(props) {


    const currentComponentValueRef = useRef(0)
    const [componentToShow, setComponentToShow] = useState(1)
    const { stateForChild } = props;

    let timeInterval = null;
    const [isCallingApi, setIsCallingApi] = useState(false);
    const [showHideSearchListContainer, setShowHideSearchListContainer] = useState(false);
    const [arrayOfSearchResults, setArrayOfSearchResults] = useState(null)

    const [showTicketBoxByStaff, setShowTicketBoxByStaff] = useState(false);
    const [openedTicketsCountState, setOpenedTicketsCountState] = useState(0);
    const [assignedTicketsCountState, setAssignedTicketsCountState] = useState(0);
    const [escalatedTicketsCountState, setEscalatedTicketsCountState] = useState(0);
    // const listOfButtons = [{ id: 1, name: "OPENED", database_id: 1, status: false, image_src: "../header_icons/ic_opened.png" }, { id: 2, name: "ASSIGNED", database_id: 3, status: false, image_src: "../header_icons/ic_assigned_to_staff.png" }, { id: 3, name: "ESCALATED", database_id: 4, status: false, image_src: "../header_icons/ic_escalated.png" }]
    const listOfButtons = [{ id: 1, name: "OPENED", database_id: 1, status: false, image_src: "../header_icons/ic_opened.png" }]
    const [ticketCategoryButtonList, setTicketCategoryButtonList] = useState(listOfButtons);

    let copyOfAllTicketList = useRef([]);
    let copyOfAllTicketListFromCustomer = useRef([]);

    const [showFilterPopupState, setshowFilterPopupState] = useState(false);
    const [showLoadingUiState, setShowLoadingUiState] = useState(false);
    const [cubixTaskTicketStaffNameArrayState, setCubixTaskTicketStaffNameArray] = useState(null);
    const [cubixTaskTicketCompanyNameArrayState, setCubixTaskTicketCompanyNameArray] = useState(null);
    const [cubixTaskTicketTicketTypeArrayState, setCubixTaskTicketTicketTypeArray] = useState(null);

    const [showAppSuccesslabel, setShowAppSuccesslabel] = useState(false);
    const [showAppFailurelabel, setShowAppFailurelabel] = useState(false);
    const [openedTicketArrayState, setOpenedTicketArrayState] = useState(null);
    const [openedTicketFromCustomerArrayState, setOpenedTicketFromCustomerArrayState] = useState(null);

    const [errorMessage, setErrorMessageState] = useState("");
    const [successMessage, setSuccessMessageState] = useState("");
    const [showCreateTicketPopup, setshowCreateTicketPopup] = useState(false);

    const showHideCreateNewTicketPopup = () => {
        setshowCreateTicketPopup(prev => !prev)
    }

    const clearErrorMessageAfterDelay = () => {
        setTimeout(() => {

            setErrorMessageState("");
            setShowAppFailurelabel(false);
        }, 5000);
    }

    const getCountOfTicketCategory = (totalArrayTicket) => {

        if (totalArrayTicket != null) {
            let openedTicketsCount = totalArrayTicket.reduce((count, item) => item.ticket_current_status == 1 ? count = count + 1 : count, 0);
            let assignedTicketsCount = totalArrayTicket.reduce((count, item) => item.ticket_current_status == 3 ? count = count + 1 : count, 0);
            let escalatedTicketsCount = totalArrayTicket.reduce((count, item) => item.ticket_current_status == 4 ? count = count + 1 : count, 0);
            console.log("openedTicketsCount is assignedTicketsCount is ", openedTicketsCount);

            setOpenedTicketsCountState(openedTicketsCount);
            setAssignedTicketsCountState(assignedTicketsCount);
            setEscalatedTicketsCountState(escalatedTicketsCount);
        }

    }

    const getAllOpenedTicketsFromCustomer = () => {

        setShowLoadingUiState(true);

        axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-opened-ticket-from-customer.php`).
            then((res) => {

                setShowLoadingUiState(false);
                console.log(" all opened tickets from customer  res is >> ", res);

                if (res.data.status == "get_opened_tickets_success") {

                    if (copyOfAllTicketListFromCustomer.current != null) {

                        if (res.data.opened_tickets_array.length == copyOfAllTicketListFromCustomer.current.length) {
                            // no new tickets, no need to refresh but we can check fr element changes

                            if (isEqual(res.data.opened_tickets_array, copyOfAllTicketListFromCustomer.current)) {

                                console.log("new and old arrays is same ")

                            } else {
                                console.log("new and old arrays is different ")

                                setOpenedTicketFromCustomerArrayState(res.data.opened_tickets_array);
                                copyOfAllTicketListFromCustomer.current = res.data.opened_tickets_array;

                                getCountOfTicketCategory(res.data.opened_tickets_array);
                            }
                        } else {
                            setOpenedTicketFromCustomerArrayState(res.data.opened_tickets_array);
                            copyOfAllTicketListFromCustomer.current = res.data.opened_tickets_array;

                            getCountOfTicketCategory(res.data.opened_tickets_array);
                        }
                    } else {

                        setOpenedTicketFromCustomerArrayState(res.data.opened_tickets_array);
                        copyOfAllTicketListFromCustomer.current = res.data.opened_tickets_array;

                        getCountOfTicketCategory(res.data.opened_tickets_array);
                    }


                } else if (res.data.status == "get_opened_tickets_failure") {

                    setShowAppFailurelabel(true);
                    setErrorMessageState("Database error, contact admin");
                    clearErrorMessageAfterDelay();
                }
            }).
            catch((err) => {

                setShowLoadingUiState(false);
                console.log("err is ", err);
                setShowAppFailurelabel(true);
                setErrorMessageState("Network error, contact admin");
                clearErrorMessageAfterDelay();
            })
    }

    const getFromLocalStorageStaffArray = () => {
        let cubixTaskTicketStaffNameArray = sessionStorage.getItem("cubix_task_ticket_staff_name_array");
        setCubixTaskTicketStaffNameArray(JSON.parse(cubixTaskTicketStaffNameArray))
    }

    const getFromLocalStorageCompanyNameArray = () => {
        let cubixTaskTicketCompanyNameArray = sessionStorage.getItem("cubix_task_ticket_company_name_array");
        setCubixTaskTicketCompanyNameArray(JSON.parse(cubixTaskTicketCompanyNameArray))
    }

    const getFromLocalStorageTicketTypeArray = () => {
        let cubixTaskTicketTicketTypeArray = sessionStorage.getItem("cubix_task_ticket_type_of_ticket_array");
        setCubixTaskTicketTicketTypeArray(JSON.parse(cubixTaskTicketTicketTypeArray))
    }

    const showHideFilterPopup = () => {
        setshowFilterPopupState(prev => !prev);
    }

    const filterTheListInParentContainer = (objectForFiltering) => {

        if (objectForFiltering != null) {
            console.log("objectForFiltering is not null ");

            if (objectForFiltering.ticket_status != null) {

                let ticketStatusfilteredArray = copyOfAllTicketList.current.filter((item) => {
                    if (objectForFiltering.ticket_status == 0) {
                        return item;
                    } else {
                        return item.ticket_current_status == objectForFiltering.ticket_status
                    }

                })

                let stafffilteredArray = ticketStatusfilteredArray.filter((item) => {
                    if (objectForFiltering.staff_name == 0) {
                        return item;
                    } else {
                        return item.ticket_status_changed_by == objectForFiltering.staff_name
                    }

                })

                let companyfilteredArray = stafffilteredArray.filter((item) => {
                    if (objectForFiltering.company_name == 0) {
                        return item;
                    } else {
                        return item.company_name == objectForFiltering.company_name
                    }

                })

                setOpenedTicketArrayState(
                    companyfilteredArray
                )

            }

        } else {
            console.log("objectForFiltering is null ");
        }

    }

    const filterTheListInParentContainerFromCustomer = (objectForFiltering) => {

        if (objectForFiltering != null) {
            console.log("objectForFiltering is not null ");

            if (objectForFiltering.ticket_status != null) {

                let ticketStatusfilteredArray = copyOfAllTicketListFromCustomer.current.filter((item) => {
                    if (objectForFiltering.ticket_status == 0) {
                        return item;
                    } else {
                        return item.ticket_current_status == objectForFiltering.ticket_status
                    }

                })

                let stafffilteredArray = ticketStatusfilteredArray.filter((item) => {
                    if (objectForFiltering.staff_name == 0) {
                        return item;
                    } else {
                        return item.ticket_status_changed_by == objectForFiltering.staff_name
                    }

                })

                let companyfilteredArray = stafffilteredArray.filter((item) => {
                    if (objectForFiltering.company_name == 0) {
                        return item;
                    } else {
                        return item.company_name == objectForFiltering.company_name
                    }

                })

                setOpenedTicketFromCustomerArrayState(companyfilteredArray)

            }

        } else {
            console.log("objectForFiltering is null ");
        }

    }


    const ticketCategoryButtonClicked = (indexOfButton) => {

        console.log("indexOfButton is listOfButtons[indexOfButton].status is ", indexOfButton, ticketCategoryButtonList[indexOfButton].status);

        if (!ticketCategoryButtonList[indexOfButton].status) {

            let filteredArray = copyOfAllTicketListFromCustomer.current.filter((item) => {
                if (item.ticket_current_status == ticketCategoryButtonList[indexOfButton].database_id) {
                    return item;
                }
            })

            // if header search box company is present then show only that companies assigned 
            // escalated, opened 

            if (stateForChild != null) {
                if (stateForChild.length != 0) {

                    let filterByCompanyArray = filteredArray.filter((item) => {
                        return item.company_account == stateForChild.account;
                    })
                    setOpenedTicketFromCustomerArrayState(filterByCompanyArray);

                } else {
                    setOpenedTicketFromCustomerArrayState(filteredArray);
                }

            } else {
                setOpenedTicketFromCustomerArrayState(filteredArray);
            }


            let mappedArray = ticketCategoryButtonList.map((item, index) => {

                if (index == indexOfButton) {
                    return ({ ...item, status: !item.state })
                } else {
                    return ({ ...item, status: false })
                }

            })

            setTicketCategoryButtonList(mappedArray);
            //

        } else {

            //
            //
            let mappedArray = ticketCategoryButtonList.map((item, index) => {

                if (index == indexOfButton) {
                    return ({ ...item, status: false })
                } else {
                    return ({ ...item, status: false })
                }

            })

            setTicketCategoryButtonList(mappedArray);
            //

            console.log("inside else of button click when state true");
            // if header search box company is present then show only that companies assigned 
            // escalated, opened 

            if (stateForChild != null) {
                if (stateForChild.length != 0) {

                    let filterByCompanyArray = copyOfAllTicketListFromCustomer.current.filter((item) => {
                        return item.company_account == stateForChild.account;
                    })
                    setOpenedTicketFromCustomerArrayState(filterByCompanyArray);

                } else {
                    setOpenedTicketFromCustomerArrayState(copyOfAllTicketListFromCustomer.current);
                }

            } else {
                setOpenedTicketFromCustomerArrayState(copyOfAllTicketListFromCustomer.current);
            }



        }
    }

    const switchCase = (dataBaseId) => {

        switch (dataBaseId) {

            case 1: return openedTicketsCountState;
            case 3: return assignedTicketsCountState;
            case 4: return escalatedTicketsCountState;

        }
    }

    const clickedOnCreateTicketByStaff = () => {

        if (stateForChild != null) {

            if (stateForChild.length != 0) {

                window.open(`${process.env.REACT_APP_FRONT_END_BASE_URL}/report_issue/${stateForChild.cmpcode}/by_cubix_staff`);

            } else {
                setShowTicketBoxByStaff(prev => !prev)
            }
        } else {
            setShowTicketBoxByStaff(prev => !prev)
        }

    }

    const clickedOnClosedTickets = () => {

        if (stateForChild != null) {

            if (stateForChild.length != 0) {

                window.open(`${process.env.REACT_APP_FRONT_END_BASE_URL}/admindashboard/allticketsfromcustomers/${stateForChild.cmpcode}`);

            } else {
                window.open(`${process.env.REACT_APP_FRONT_END_BASE_URL}/admindashboard/allticketsfromcustomers`);
            }
        } else {
            window.open(`${process.env.REACT_APP_FRONT_END_BASE_URL}/admindashboard/allticketsfromcustomers`);
        }

    }

    const callApi = (searchingText) => {

        axios.get(`https://cubixweberp.com:200/api/CustomerDetails/CUST_SER/${searchingText}`).then((res) => {

            console.log("companys search res is ", res.data);
            setIsCallingApi(false);

            if (res.status == 204) {
                setArrayOfSearchResults([]);
            } else {
                setArrayOfSearchResults(res.data);
            }

        }).catch(err => {
            setIsCallingApi(false);
            console.log("error is ", err)
        })

    }

    const handleInput = (e) => {

        clearInterval(timeInterval);

        if (e.target.value.length > 0) {

            setShowHideSearchListContainer(true);

            timeInterval = setTimeout(() => {

                console.log(" searchtext changed is", e.target.value);
                if (timeInterval) {
                    console.log("timer is not null we are clearing it")
                    clearInterval(timeInterval);
                }

                callApi(e.target.value);
                setIsCallingApi(true);


            }, 500)

        } else {
            setShowHideSearchListContainer(false)
        }

    }

    const setDetailsOfCompanyOnClick = (indexOfArrayItem) => {
        console.log("clicked on indexOfArrayItem ", indexOfArrayItem, arrayOfSearchResults[indexOfArrayItem]);

        window.open(`${process.env.REACT_APP_FRONT_END_BASE_URL}/report_issue/${arrayOfSearchResults[indexOfArrayItem].cmpcode}/by_cubix_staff`);

    }


    useEffect(() => {

        if (stateForChild != null) {
            if (stateForChild.length != 0) {

                let filteredArray = copyOfAllTicketListFromCustomer.current.filter((item) => {
                    if (item.company_account == stateForChild.account) {
                        return item;
                    }
                })
                console.log(" filteredArray TicketInHomeComponent", filteredArray);

                setOpenedTicketFromCustomerArrayState(filteredArray);

            } else {
                setOpenedTicketFromCustomerArrayState(copyOfAllTicketListFromCustomer.current);
            }
        }

    }, [stateForChild])


    useEffect(() => {

        // first time
        // getAllOpenedTickets();
        getAllOpenedTicketsFromCustomer();

        const adminUserName = localStorage.getItem("cubix_support_admin_username");

        console.log(" admin username ", adminUserName);

        if (adminUserName == "admin_tv_monitor") {
            // from second time onwards
            setInterval(() => {

                getAllOpenedTicketsFromCustomer();
            }, 300000); // refresh after 5 minutes
        }

        getFromLocalStorageStaffArray();
        getFromLocalStorageCompanyNameArray();
        getFromLocalStorageTicketTypeArray();


    }, [])

    const componentSwitcher = () => {

       // console.log("currentComponentValueRef.current ", currentComponentValueRef.current)

        setComponentToShow(currentComponentValueRef.current)

        if (currentComponentValueRef.current + 1 > 5) {
            currentComponentValueRef.current = 1

            console.log("currentComponentValueRef.current + 1 < 5 ")
        } else {
            currentComponentValueRef.current = currentComponentValueRef.current + 1

           // console.log("currentComponentValueRef.current + 1 > 5 ")
        }

    }

    useEffect(() => {

        setInterval(() => {
            componentSwitcher()
        }, 5000)


    }, [])

    return (
        <div className="TicketsInHomeComponentTvMonitor-root-container">


            <div className="d-flex">

                <div className="admin-tv-monitor-left-side">

                    {
                        componentToShow == 1 &&
                        <Slider_1 />
                    }

                    {
                        componentToShow == 2 &&
                        <Slider_2 />
                    }

                    {
                        componentToShow == 3 &&
                        <Slider_3 />
                    }

                    {
                        componentToShow == 4 &&
                        <Slider_4 />
                    }

                    {
                        componentToShow == 5 &&
                        <Slider_5 />
                    }

                </div>

                <div className="admin-tv-monitor-right-side">

                    {showLoadingUiState && <LoadingUI />}

                    <div className="TicketsInHomeComponentTvMonitor-list-container">

                        {showAppSuccesslabel &&
                            <div className="App-success-label"><label>{successMessage}</label></div>
                        }
                        {showAppFailurelabel &&
                            <div className="App-failure-label"><label>{errorMessage}</label></div>
                        }
                        {
                            openedTicketFromCustomerArrayState != null ?
                                openedTicketFromCustomerArrayState.length > 0 ?
                                    <TicketSingleCardSupportTvMonitor openedTicketFromCustomerArrayState={openedTicketFromCustomerArrayState} cubixTaskTicketStaffNameArrayState={cubixTaskTicketStaffNameArrayState} cubixTaskTicketCompanyNameArrayState={cubixTaskTicketCompanyNameArrayState} cubixTaskTicketTicketTypeArrayState={cubixTaskTicketTicketTypeArrayState} />
                                    : "No Opened Tickets"
                                : null
                        }

                    </div>
                </div>


            </div>

            <Marquee>
                <img className="marquee_img" src={roll1} />
                <img className="marquee_img" src={roll2} />
                <img className="marquee_img" src={roll3} />
                <img className="marquee_img" src={roll4} />
                <img className="marquee_img" src={roll5} />
                <img className="marquee_img" src={roll6} />
                <img className="marquee_img" src={roll7} />

                <img className="marquee_img" src={roll1} />
                <img className="marquee_img" src={roll2} />
                <img className="marquee_img" src={roll3} />
                <img className="marquee_img" src={roll4} />
                <img className="marquee_img" src={roll5} />
                <img className="marquee_img" src={roll6} />
                <img className="marquee_img" src={roll7} />
            </Marquee>

        </div>
    )
}

export default TicketsInHomeComponentSupportTvMonitor;